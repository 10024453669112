import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/meetingpoint',
    name: 'Meetpoint',
    component: () => import('../views/Treffpunkt.vue')
  },
  {
    path:'/weathernwater',
    name:'weathernwater',
    component: () => import('../views/weatherwater.vue')
  },
  {
    path:'/signs',
    name:'Sings',
    component: () => import('../views/signs.vue')
  },
  {
    path:'/contact',
    name:'Contact',
    component: () => import('../views/Contact.vue')
  },
  {
    path:'/gallery',
    name:'Gallery',
    component: () => import('../views/Gallery.vue')
  },
  {
    path: '/agenda',
    name: 'Agenda',
    component: () => import('../views/Agenda.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
