<template>
    <nav id="nav" class="topnav">
        <img alt="KCAE" src="../assets/KCAE.png">
        <div class="logoborder"/>
        <div class="menucontainer">
        <router-link to="/">Startseite</router-link>
        <router-link to="/meetingpoint">Treffpunkt</router-link>
        <router-link to="/weathernwater">Wetter und Wasser</router-link>
        <router-link to="/agenda">Agenda 2024</router-link>
        <router-link to="/signs">Kanuzeichen</router-link>
        <router-link to="/contact">Kontakt</router-link>
        <router-link to="/gallery">Gallerie</router-link>
        <a href="javascript:void(0);" class="icon" @click="PopMenu()">Menü</a>
        </div>
    </nav>
</template>

<script>
export default {
  name: 'Header',
  methods:{
      PopMenu: function(){
            var x = document.getElementById("nav");
            if (x.className === "topnav") 
            {
                x.className += " responsive";
            } else 
            {
                x.className = "topnav";
            }
        }
    }
}
</script>

<style scoped>
nav{
    background-color:#fff;
    color: #4a4a4a;
    min-height: 70px;
    position: relative;
    z-index: 100;
}

nav img{
    height: 160px;
    border-bottom: solid #2c3e50;
    position: absolute;
    left: 10px;
}

.logoborder{
    width: 345px;
    height: 90px;
    position: absolute;
    border-left: solid #2c3e50;
    border-right: solid #2c3e50;
    border-bottom: solid #2c3e50;
    margin-top: 70px;
    position:absolute;
    left: 10px;
}

nav #branding{
    float: left;
}

.menucontainer{
    height: 70px;
    padding: 0px 20px 0px 20px;
    line-height: 70px;
    left: 340px;
    position: absolute;
}

nav div a{
    height: 70px;
    padding: 0px 20px 0px 20px;
    line-height: 70px;
    overflow: hidden;
    /*float: left;*/
}

.topnav .icon{
    display: none;
}

@media screen and (max-width:1570px){
    .topnav a:not(:nth-child(-n+4)){display:none;}
    .topnav a.icon{
        float:right;
        display: block;
    }
}

@media screen and (max-width:1570px){
    .topnav.responsive
    {
        position:relative;
    }
    .topnav.responsive .icon{
        position: relative;
        right: 0;
        top: 0;
        background-color: #fff;
    }    
    .topnav.responsive a:not(:nth-child(-n+4)):first-child{
        top: 70px;
    }
    .topnav.responsive a:not(:nth-child(-n+4)){
        float: none;
        display: block;
        text-align: left;
        background-color: #fff;
    }
    .topnav img{
        height: 140px;
    }
    .topnav .logoborder{
        width: 300px;
        height: 70px;
    }
    .topnav .menucontainer{
        left: 300px;
    }
}

@media screen and (max-width:1200px){
    .topnav a:not(:nth-child(-n+3)){display:none;}
    .topnav a.icon{
        float:right;
        display: block;
    }
}

@media screen and (max-width:1200px){
    .topnav.responsive
    {
        position:relative;
    }
    .topnav.responsive .icon{
        position: relative;
        right: 0;
        top: 0;
        background-color: #fff;
    }    
    .topnav.responsive a:not(:nth-child(-n+3)):first-child{
        top: 70px;
    }
    .topnav.responsive a:not(:nth-child(-n+3)){
        float: none;
        display: block;
        text-align: left;
        background-color: #fff;
    }
    .topnav img{
        height: 140px;
    }
    .topnav .logoborder{
        width: 300px;
        height: 70px;
    }
    .topnav .menucontainer{
        left: 300px;
    }
}

@media screen and (max-width:1030px){
    .topnav a:not(:nth-child(-n+1)){display:none;}
    .topnav a.icon{
        float:right;
        display: block;
    }
}

@media screen and (max-width:820px){
    .topnav.responsive
    {
        position:relative;
    }
    .topnav.responsive .icon{
        position: relative;
        right: 0;
        top: 0;
        background-color: #fff;
    }    
    .topnav.responsive a:not(:nth-child(-n+1)):first-child{
        top: 70px;
    }
    .topnav.responsive a:not(:nth-child(-n+1)){
        float: none;
        display: block;
        text-align: left;
        background-color: #fff;
    }
    .topnav img{
        height: 140px;
    }
    .topnav .logoborder{
        width: 300px;
        height: 70px;
    }
    .topnav .menucontainer{
        left: 300px;
    }
}

@media screen and (max-width:600px){
    .topnav a:not(:last-child){display: none;}
    .topnav a.icon{
        float:right;
        display: block;
    }
}

@media screen and (max-width: 600px){
    .topnav.responsive
    {
        position:relative;
    }
    .topnav.responsive .icon{
        position: relative;
        right: 0;
        top: 0;
    }    
    .topnav.responsive a{
        float: none;
        display: block;
        text-align: left;
        background-color: #fff;
    }
    .topnav img{
        height: 80px;
    }
    .topnav .logoborder{
        width: 170px;
        height: 10px;
    }
    .topnav .menucontainer{
        left: 170px;
    }
}

</style>
