<template>
  <div id="app">
    <Header/>
    <router-view id="content"/>
  </div>
</template>

<script>
import Header from './components/Header.vue'

export default {
  name: 'app',
  components: {
    Header
  }
}
</script>

<style>
body{
  margin: 0;
  padding: 0;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  height: 100%;
  width: 100%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  background-color: #fff;
  padding: 0px 20px 0px 20px;
  border-bottom: #2c3e50 solid;
  position: fixed;
  width: 100%;
  top: 0;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#content{
  /*padding-top: 80vh;*/
}

#nav a.router-link-exact-active {
  color: #42b983;
  background-color: white;
}

.parallax{
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size:cover;
}

.parallax-home{
    background-image: url("assets/Title_New.jpeg");
    max-height:70%;
    padding-bottom:50%;
    position:relative;
}

</style>
