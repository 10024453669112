<template>
  <div>
    <div class="container">
      <video playsinline autoplay loop muted class="video-parallax">
        <source src="../assets/Durance2020V3.webm" type="video/webm">
      </video>  
    </div>
    <div id="myModal" class="modal">
      <div class="modal-content">
        <span class="close" v-on:click="closeimage">&times;</span>
        <img src="../assets/Schnuppertag.jpg" class="img-fluid-solo"/>
      </div>
    </div>
    <!--<div class="infocontainer">
      <div>
        <h1>News</h1>
      </div>
      <div>
        <h3>
            Diese Jahr findet der Kanuschnuppertag am 29.04.2023 statt!
        </h3>
        <button class="defaultButton" v-on:click="openimage(image)">
          Schnuppertagsflyer
        </button>
      </div>
    </div>-->
    <div class="videocontainer">
      <div>
        <a href="https://www.youtube.com/watch?v=xUEnOUmTr0I&t=3s&ab_channel=KCAE" target="_blank">zum Video</a>
      </div>
    </div>
    <div class="headercontainer">
      <div>
        <h1>Über uns</h1>
      </div>
    </div>
    <div class="top-div-decoration">
      <h1>"</h1>
    </div>
    <div class="container">
      <div>
          <p>
            Ob im steil tosenden Wildbach oder in der gemächlich mäandrierenden Aue, ob im Kajak oder Kanadier, bei uns sind alle Paddler willkommen! Und jene, die es werden wollen!
          </p>
          <p>
            Der KCAE paddelt im Frühling regelmässig auf der Kleinen Emme, ab und zu machen wir Ausflüge in benachbarte Kanu-Regionen und im Sommer geht's für eine Woche in ein abwechslungsreiches Kanulager.
          </p>
          <p>
            Falls du dich mal in einem Kajak versuchen möchtest, nimm einfach Kontakt mit uns auf! Eine gute Gelegenheit zum Ausprobieren bietet unser Kanu-Schnupper-Tag, jeweils im April!
          </p>
          <p>
            Falls du bereits paddelst und neu in der Region bist, melde dich einfach, wir nehmen dich gerne mal mit auf unsere Emme!
          </p>
          <p>
            Der KCAE wünscht dir viel Spass auf dem Wasser, bis bald!
          </p>
        </div>
    </div>
    <h1 class="bottom-div-decoration-text">"</h1>
    <div class="bottom-div-decoration"></div>
    <div class="headercontainer">
      <div><h1>Medien</h1></div>
    </div>
    <div class="container clear">
      <div>
        <iframe 
          src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fkanuentlebuch%2F&amp;tabs=timeline&amp;width=300&amp;height=500&amp;small_header=false&amp;adapt_container_width=true&amp;hide_cover=false&amp;show_facepile=true&amp;appId" width="300" height="500" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowtransparency="true">
        </iframe>
      </div>
    </div>
    <div class="headercontainer">
      <div>
        <h3>KCAE</h3>
        <h6>Copyright © 2021 Kanu Club Amt Entlebuch</h6>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  props: {
    msg: String
  },
  methods:{
    openimage:function(image){
      var modal = document.getElementById("myModal");
      modal.style.display = "block";
      this.currentimage = image;
    },
    closeimage:function(){
      var modal = document.getElementById("myModal");
      modal.style.display = "none";
    },
  }
}
</script>

<style scoped>

.videocontainer{
  position: absolute;
}

.infocontainer{
  top: 200px;
  position: absolute;
  background-color: rgba(80, 80, 80, 0.0);;
  height: 100px;
  width: 100%;
}

.headercontainer{
  display:flex;
  background-color: rgba(80, 80, 80, 0.6);
}

@media screen and (max-width: 400px){
  .headercontainer{
    background-color: transparent;
    display:flex;
    background-color: rgba(80, 80, 80, 0.6);
    padding-top:40px;
  }
}

.headercontainer h1{
  color: white;
}

@supports (-webkit-text-stroke: 1px black) {
  .headercontainer h1 {
    -webkit-text-stroke: 2px #2c3e50;
  }
  .headercontainer h3 {
    -webkit-text-stroke: 2px #2c3e50;
  }
  .headercontainer h6 {
    -webkit-text-stroke: 0.5px #2c3e50;
  }
  .videocontainer a {
    color: white;
    -webkit-text-stroke: 0.1px #2c3e50;
    margin-left: 20px;
    cursor: pointer;
    font-size: 30px;
  }
}

.infocontainer h1{
  color: white;
  font-size: 60px;
}
.infocontainer h3{
  color: white;
  font-size: 35px;
}

@supports (-webkit-text-stroke: 1px black) {
  .infocontainer h1 {
    -webkit-text-stroke: 2px #2c3e50;
  }
    .infocontainer h3 {
    -webkit-text-stroke: 2px #2c3e50;
  }
}

video{
  z-index: -100;
}

.video-parallax {
    -webkit-transition-position: fixed;
    position: fixed;
}

.headercontainer > div{
  background-color:transparent;
  flex:1;
  color: white;
  font-size: 25px;
}

.container{
  background-color: white;
  display:flex;
}

.container:first-child{
  height: 80vh;
  background-color: rgba(80, 80, 80, 0.6);
}

.container > div{
  flex:1;
  background-color:transparent;
  color: #2c3e50;
  font-size: 16px;
  margin: 25px 8vw 10px 8vw;
}

.clear{
  background-color: transparent;
  background-color: rgba(80, 80, 80, 0.6);
}

.container div p{
  font-weight: bold;
}

.top-div-decoration{
  border-bottom: 75px solid white;
  border-right: 100vw solid transparent;
  background-color: rgba(80, 80, 80, 0.6);
}

.top-div-decoration h1{
  position: absolute;
  left: 100px;
  font-size: 80px;
}

.bottom-div-decoration {
  border-top: 75px solid white;
  border-left: 100vw solid transparent;
  background-color: rgba(80, 80, 80, 0.6);
}

.bottom-div-decoration-text{
  position: absolute;
  right: 120px;
  font-size: 80px;
  margin-top: 0px;
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 101; /* Sit on top */
  padding-top: 50px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: rgba(80, 80, 80, 0.6);;
  margin: auto;
  padding: 20px;
  border: 1px solid rgba(80, 80, 80, 0.6);;
  width: 50%;
  border-radius: 10px;
}

/* The Close Button */
.close {
  background-color: white;
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  padding: 25px;
  border-radius: 10px;
}

.close:hover,
.close:focus {
  background-color: grey;
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.img-fluid-solo{
  width:auto;
  height: 80vh;
}

.defaultButton{
  background-color: #2c3e50;
  color: white;
  border-radius: 5px;
  padding: 15px 32px;
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 15px;
  border: 2px solid white;
}

@media screen and (max-width: 600px){
    .defaultButton{
      background-color: #2c3e50;
      color: white;
      border-radius: 5px;
      padding: 10px 20px;
      border: none;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      margin: 5px;
      border: 1px solid white;
  }
}

.news-title{
  font-size: 25px;
  margin: 0px;
}

</style>
